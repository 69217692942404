<template>
  <v-row
    class="justify-center home-gallery"
  >
    <v-col
      v-if="galleryOne"
      class="text-center"
      cols="12"
      md="3"
      sm="6"
      xl="3"
    >
      <v-carousel
        v-model="sliderOneTitle"
        style="margin: 0 auto"
        hide-delimiters
        :show-arrows="false"
        height="100px"
        class="mr-4 text-center"
        cycle
      >
        <v-carousel-item
          v-for="(item,i) in galleryOne"
          :key="i"
          :transition="false"
        >
          <v-img
            :src="getImgFromCollectionUrl(item)"
            :contain="true"
            max-width="100px"
            height="100px"
          />
        </v-carousel-item>
      </v-carousel>
      <div class="home-gallery__desc">
        {{ galleryOneTitle }}
      </div>
    </v-col>
    <v-col
      v-if="galleryTwo"
      class="text-center"
      cols="12"
      md="3"
      sm="6"
      xl="3"
    >
      <v-carousel
        v-model="sliderTwoTitle"
        hide-delimiters
        :show-arrows="false"
        height="100px"
        class="mr-4 text-center"
        cycle
      >
        <v-carousel-item
          v-for="(item,i) in galleryTwo"
          :key="i"
          :transition="false"
        >
          <v-img
            :src="getImgFromCollectionUrl(item)"
            :contain="true"
            max-width="100px"
            height="100px"
          />
        </v-carousel-item>
      </v-carousel>
      <div class="home-gallery__desc">
        {{ galleryTwoTitle }}
      </div>
    </v-col>
    <v-col
      v-if="galleryThree"
      class="text-center"
      cols="12"
      md="3"
      sm="6"
      xl="3"
    >
      <v-carousel
        v-model="sliderThreeTitle"
        hide-delimiters
        :show-arrows="false"
        height="100px"
        class="text-center"
        cycle
      >
        <v-carousel-item
          v-for="(item,i) in galleryThree"
          :key="i"
          :transition="false"
        >
          <v-img
            :src="getImgFromCollectionUrl(item)"
            :contain="true"
            max-width="100px"
            height="100px"
          />
        </v-carousel-item>
      </v-carousel>
      <div class="home-gallery__desc">
        {{ galleryThreeTitle }}
      </div>
    </v-col>
    <v-col
      v-if="galleryFour"
      class="text-center"
      cols="12"
      md="3"
      sm="6"
      xl="3"
    >
      <v-carousel
        v-model="sliderThreeTitle"
        hide-delimiters
        :show-arrows="false"
        height="100px"
        class="text-center"
        cycle
      >
        <v-carousel-item
          v-for="(item,i) in galleryThree"
          :key="i"
          :transition="false"
        >
          <v-img
            :src="getImgFromCollectionUrl(item)"
            :contain="true"
            max-width="100px"
            height="100px"
          />
        </v-carousel-item>
      </v-carousel>
      <div class="home-gallery__desc">
        {{ galleryThreeTitle }}
      </div>
    </v-col>
  </v-row>
</template>

<script>
  // Mixins
  import Heading from '@/mixins/heading'
  import getImgFromCollectionUrl from '@/utils/getImgFromCollectionUrl.js'

  export default {
    name: 'HomeGalery',
    mixins: [Heading],

    props: {
      galleryOne: {
        type: Array,
      },
      galleryTwo: {
        type: Array,
      },
      galleryThree: {
        type: Array,
      },
      galleryFour: {
        type: Array,
      },
      galleryOneTitle: {
        type: String,
      },
      galleryTwoTitle: {
        type: String,
      },
      galleryThreeTitle: {
        type: String,
      },
      galleryFourTitle: {
        type: String,
      },
    },
    data () {
      return {
        sliderOneTitle: 0,
        sliderTwoTitle: 1,
        sliderThreeTitle: 2,
      }
    },
    methods: {
      getImgFromCollectionUrl,
    },

  }
</script>

<style lang="scss" scoped>
  .home-gallery{
    .v-image {
      margin: 0 auto;
    }
    &__desc{
      width: 100px;
      margin: 0 auto;
    }
  }

</style>
