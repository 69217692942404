<template>
  <div
    :class="classes"
    class="pt-2 d-flex flex-column text-center justify-space-between fill-height"
  >
    <base-avatar
      v-if="icon"
      :color="color"
      :dark="dark"
      :icon="icon"
      :outlined="outlined"
      :size="size"
      class="mb-3"
    />

    <div
      :class="horizontal && title && 'ml-6'"
      class="fill-height d-flex align-center"
    >
      <div
        class="text-lowercase text-center"
        space="3"
        v-html="title"
      />
    </div>
  </div>
</template>

<script>
  import { documentToHtmlString } from '@contentful/rich-text-html-renderer'
  // Mixins
  import Heading from '@/mixins/heading'
  import BaseTextField from './base/TextField'

  export default {
    name: 'HomeFeatureCard',
    components: { BaseTextField },
    mixins: [Heading],

    props: {
      align: {
        type: String,
        default: 'left',
      },
      color: String,
      dark: Boolean,
      horizontal: Boolean,
      icon: String,
      outlined: {
        type: Boolean,
        default: true,
      },
      space: {
        type: [Number, String],
        default: 0,
      },
      size: {
        type: [Number, String],
        default: 40,
      },
      text: Object,
      title: String,
    },

    computed: {
      classes () {
        const classes = [
          `mb-${this.space}`,
        ]

        if (this.horizontal) {
          classes.push('d-flex')

          if (!this.$slots.default && !this.text) {
            classes.push('align-center')
          }
        }

        return classes
      },
    },
    methods: {
      documentToHtmlString,
    },
  }
</script>
